<template>
  <SingleCard>
    <div class="info">
      <span class="header-title">{{ $locale["files"] }}</span>
      <span class="header-total">{{ number_format(total) }}</span>
    </div>
    <div class="options">
      <a class="primary-circle" @click="createFile"><iconic name="plus" /></a>
    </div>
  </SingleCard>
</template>

<script>
export default {
  props: ["total"],
  methods: {
    createFile: function() {
      this.$store.state.user.newFile = true;
    },
  },
};
</script>

<style lang="scss">
.user-files-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-title {
    font-weight: bold;
  }
  .header-total {
    margin: 0 0 0 $mpadding/2;
    opacity: 0.7;
  }
}
</style>
